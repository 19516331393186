import React, { useEffect } from 'react'
import { shape, string, func, number } from 'prop-types'
import InputField from '../../InputField/InputField'
import {
  vehicleSections,
  vehicleInputs
} from '../../../redux/vehicleSelections/constants'
import {
  generateFloatingVehicleLengthLabel,
  renderVehicleError
} from '../../../redux/vehicleSelections/utilities'

const VehicleAdditionalDetails = ({
  formOrder,
  formValues,
  formErrors,
  labels,
  tooltips,
  handleChange,
  openModal,
  modalIsOpen,
  clientContactPhoneNumber
}) => {
  const formClass = 'passenger-vehicles__details-form',
    leftLabel = `${labels.vehicleDetails}`,
    licensePlateSubtext = labels.licensePlateInstruction,
    vehicleLengthPlaceholder = `${labels.vehicleLength} (${labels.inFeet})`

  useEffect(() => {
    if (formValues.vehicleType.licensePlateNumberRequired) {
      const licenseInput = document.querySelector('.vehicle-license-plate-number')
      if (licenseInput) {
        licenseInput.focus()
      }
    }
  }, [formValues.vehicleType.licensePlateNumberRequired, formValues.vehicleType])

  return (
    <div
      className="passenger-vehicles__details"
      style={{
        order: formOrder
      }}
    >
      <div className={formClass}>
        {leftLabel && <div className="left-label">{leftLabel}</div>}
        {formValues.vehicleType.licensePlateNumberRequired && (
          <div>
            <InputField
              placeholder={labels.licensePlateNumber}
              type="text"
              onChange={e => {
                handleChange(vehicleInputs.LICENSE, e.target.value)
              }}
              useFloatingLabel
              value={formValues.vehicleLicense ? formValues.vehicleLicense : ''}
              inputClassNames="vehicle-license-plate-number"
              error={
                formErrors && formErrors[vehicleInputs.LICENSE]
                  ? renderVehicleError({
                      labels,
                      errorType: formErrors[vehicleInputs.LICENSE]
                    })
                  : false
              }
            />
            {licensePlateSubtext && (
              <div className="passenger-vehicles__details-form-subtext">
                {licensePlateSubtext}
              </div>
            )}
          </div>
        )}
        {formValues.vehicleType.lengthRequired && (
          <div>
            <InputField
              placeholder={vehicleLengthPlaceholder}
              onChange={e => {
                const val = e.target.value.replace(/\D/g, '')
                if (!isNaN(val)) {
                  handleChange(vehicleInputs.LENGTH, val)
                }
              }}
              value={
                formValues.vehicleLength
                  ? formValues.vehicleLength.toString()
                  : ''
              }
              inputClassNames="vehicle-lengths"
              parentClassNames={'vehicle-length'}
              error={
                formErrors && formErrors[vehicleInputs.LENGTH]
                  ? renderVehicleError({
                      labels,
                      errorType: formErrors[vehicleInputs.LENGTH],
                      minLength: formValues?.[vehicleSections.LENGTH]?.minVal,
                      maxLength: formValues?.[vehicleSections.LENGTH]?.maxVal,
                      phone: clientContactPhoneNumber
                    })
                  : false
              }
              useFloatingLabel
              floatingLabel={generateFloatingVehicleLengthLabel({
                labels,
                minVal: formValues?.[vehicleSections.LENGTH]?.minVal,
                maxVal: formValues?.[vehicleSections.LENGTH]?.maxVal
              })}
            />
            {tooltips && tooltips['length'] && tooltips['length'] && (
              <div className="passenger-vehicles__details-form-tooltip-container">
                <p className="u-remove-margin">
                  {labels.howToMeasureLengthOfVehicle}
                </p>
                <div
                  onClick={() => {
                    if (
                      !Object.keys(modalIsOpen).includes('vehicle-length-modal')
                    ) {
                      openModal({ type: 'vehicle-length-modal' })
                    }
                  }}
                  className="vehicle-tooltip-trigger-container u-display-flex"
                >
                  <span
                    className="u-tooltip-trigger"
                    data-tooltip="height-tooltip"
                  >
                    ?
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

VehicleAdditionalDetails.propTypes = {
  formOrder: number.isRequired,
  formValues: shape().isRequired,
  formErrors: shape(),
  labels: shape().isRequired,
  tooltips: shape(),
  handleChange: func.isRequired,
  openModal: func.isRequired,
  modalIsOpen: shape().isRequired,
  clientContactPhoneNumber: string
}

export default VehicleAdditionalDetails
