import React from 'react'
import { arrayOf, string, func, object, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { getVehicleFilterOptions } from '../../redux/vehicleSelections/constants'
import {
  selectLabels,
  selectBicycleResourceCode
} from '../../redux/configuration/selectors'
import { ENABLE_BICYCLE_OPTION } from '../../configuration/constants'
import { crossingTypes } from '../../redux/crossings/constants'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import bicycleIcon from '../../assets/icons/pngs/vehicle-types/bicycle.png'
import bicycleIconHighlighted from '../../assets/icons/pngs/vehicle-types-green/bicycle.png'

const AreYouBringingAVehicle = ({
  activeSelection, // internal key - top-level form filter
  selectionKeys, // internal keys - form filter options
  updateSelection,
  clearVehicleSelections,
  crossingData,
  vehicleOptions,
  language,
  handleVehicleSelection,
  returnVehicleOnly,
  oneWay
}) => {
  const labels = useSelector(selectLabels)
  const bicycleResourceCode = useSelector(selectBicycleResourceCode)
  const allVehicleFilterOptions = getVehicleFilterOptions()
  const possibleLabels = {
    yesLabel:
      oneWay && labels.bringingAVehicleOneWayYes
        ? labels.bringingAVehicleOneWayYes
        : labels.yes,
    sameLabel: labels.same,
    vehicleOnBothRoutesLabel: labels.vehicleOnBothRoutes,
    differentLabel: labels.different,
    noWalkingOnLabel: labels.noWalkingOn,
    bicycleLabel: labels.bicycleWalkOnMsg
  }

  return (
    <div
      className={`passenger-vehicles__initial-form ${
        activeSelection ? 'has-selection' : ''
      }`}
      data-testid={'per-trip-vehicle-declaration'}
      role="radiogroup"
    >
      {selectionKeys.map(optionType => {
        const option = allVehicleFilterOptions?.[optionType]
        const isSelected = Boolean(
          activeSelection && activeSelection === optionType
        )

        let expandedContent = false
        let lowestPrice = 0
        let vehicle = null
        let expandedIcon = bicycleIcon

        if (ENABLE_BICYCLE_OPTION && optionType === bicycleResourceCode) {
          expandedContent = true
          const departureCost =
            crossingData[crossingTypes.DEPARTURE]?.resourceBestRates[optionType]
              ?.price
          const returnCost =
            crossingData[crossingTypes.RETURN]?.resourceBestRates[optionType]
              ?.price
          lowestPrice =
            !returnCost || departureCost < returnCost
              ? departureCost
              : returnCost
          vehicle =
            crossingData[crossingTypes.DEPARTURE]?.resourceBestRates[optionType]
          vehicle = vehicleOptions?.vehicles.filter(
            veh => veh.resourceCode === bicycleResourceCode
          )[0]
          if (isSelected) {
            expandedIcon = bicycleIconHighlighted
          }
        }

        return activeSelection && !isSelected ? null : (
          <div
            tabIndex={0}
            key={optionType}
            className={`passenger-vehicles__initial-form-toggle ${
              isSelected ? 'active' : ''
            } ${
              ENABLE_BICYCLE_OPTION && optionType === bicycleResourceCode
                ? 'passenger-vehicles__expanded-container'
                : ''
            }`}
            onClick={() => {
              if (expandedContent && !isSelected) {
                handleVehicleSelection(vehicle)
              }
              updateSelection(isSelected ? '' : optionType)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (expandedContent && !isSelected) {
                  handleVehicleSelection(vehicle)
                }
                updateSelection(isSelected ? '' : optionType)
              }
            }}
            aria-label={option.ariaLabel({ ...possibleLabels })}
            role="radio"
            aria-checked={isSelected}
          >
            <div className="passenger-vehicles__label-container">
              <div
                className="toggle-label"
                dangerouslySetInnerHTML={{
                  __html: option.renderLabel({ ...possibleLabels })
                }}
              />
              {expandedContent && (
                <>
                  <hr className="passenger-vehicles__linebreak" />
                  <div className="passenger-vehicles__expanded">
                    <div className="passenger-vehicles__expanded__meta">
                      <div className="passenger-vehicles__expanded__icons">
                        <img
                          src={expandedIcon}
                          alt={option.ariaLabel({ ...possibleLabels })}
                          className="passenger-vehicles__expanded__icons__image"
                          role="none"
                        />
                      </div>
                    </div>
                    <div className="passenger-vehicles__expanded__price">
                      <div className="passenger-vehicles__expanded__price__label">
                        {labels.startingFrom}
                      </div>
                      <div className="passenger-vehicles__expanded__price__value">
                        {formatPriceByLanguage(lowestPrice, language)}
                      </div>
                      <div className="passenger-vehicles__expanded__price__suffix">
                        {labels.taxFeeDesc}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      })}
      {activeSelection && (
        <div
          key={activeSelection}
          className="passenger-vehicles__initial-form-clear"
          onClick={() => clearVehicleSelections()}
          tabIndex={0}
        >
          <p className="form-clear">
            <i className="material-icons">close</i>
            {` ${labels.clear}`}
          </p>
        </div>
      )}
    </div>
  )
}

AreYouBringingAVehicle.defaultProps = {
  oneWay: false
}

AreYouBringingAVehicle.propTypes = {
  activeSelection: string.isRequired,
  selectionKeys: arrayOf(string).isRequired,
  updateSelection: func.isRequired,
  clearVehicleSelections: func.isRequired,
  crossingData: object.isRequired,
  language: string.isRequired,
  handleVehicleSelection: func.isRequired,
  oneWay: bool
}

export default AreYouBringingAVehicle
