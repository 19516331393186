import React, { useEffect, useRef, useState } from 'react'

import { MapBrowserEvent, Map, View, Overlay } from 'ol'

import { GeoJSON } from 'ol/format'
import { getCenter } from 'ol/extent'
import { Polygon } from 'ol/geom'
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import {
  Select as SelectInteraction,
  defaults as createInteractions
} from 'ol/interaction'

import ImageLayer from 'ol/layer/Image'
import Projection from 'ol/proj/Projection'
import Static from 'ol/source/ImageStatic'

import navRightMapIcon from '../../assets/icons/map-right-nav.svg'
import navLeftMapIcon from '../../assets/icons/map-left-nav.svg'

import { pickerSelectionStyle } from './olStyles'
import MapSectionIndicator from './MapSectionIndicator'
import NavigationButton from './NavigationButton'

const MAP_POPUP_SIZE = [234, 276]
const DESKTOP_WIDTH_MIN = 768
const DEFAULT_KEY_NAV_BOUNDS = 0.4

/**
 * default seatmap component
 *
 * @param {object} props
 * @returns
 */
const ResponsiveSeatMap = ({
  enablePan,
  enableZoom,
  imageUrl,
  imageExtent,
  featuresJson,
  overlaySelector,
  mapClickHandler,
  featureSelectHandler,
  popupRepositionHandler,
  getFeatureStyle,
  unavailableSeatFilter,
  selectedFeature,
  onFeatureSpacebarKeydown,
  labels,
  onAddSeatClick,
  onRemoveSeatClick,
  feature,
  isSelected
}) => {
  const [tileIndex, setTileIndex] = useState(0)
  const [totalTiles, setTotalTiles] = useState(2)
  const selectedSeatNumber = selectedFeature?.get('seatNumber')

  const mapElementRef = useRef()
  const mapObjectRef = useRef(null)

  const getMap = () => mapObjectRef.current
  const scrollMapTo = direction =>
    getMap().scrollVisibleSectionMobile(direction)
  const mapScrollHandler = (index, total) => {
    setTileIndex(index)
    setTotalTiles(total)
  }
  const seatNodes = generateSeatNodes(featuresJson)
  const firstNode = seatNodes.reduce(
    (acc, cur) => {
      if (
        cur.position[0] < acc.position[0] &&
        cur.position[1] > acc.position[1]
      ) {
        acc = cur
      }
      return acc
    },
    { position: [9999, 0] }
  )

  const showLeftButton = () => tileIndex > 0 && tileIndex < totalTiles
  const showRightButton = () => tileIndex < totalTiles - 1

  // initialize map only once unless the dependencies changed
  useEffect(() => {
    if (mapObjectRef.current) return

    const overlayElement = document.querySelector(overlaySelector)
    const selectFilter = feature => !unavailableSeatFilter(feature)
    const options = { enablePan, enableZoom, selectFilter }
    const seatMap = new CoreSeatMap(mapElementRef.current, overlayElement)
    seatMap.init(imageUrl, imageExtent, featuresJson, options)
    seatMap.addMapContainerResizeListener()
    seatMap.setPopupSize(MAP_POPUP_SIZE)
    // cache the sole map instance
    mapObjectRef.current = seatMap
  }, [
    imageUrl,
    imageExtent,
    featuresJson,
    overlaySelector,
    mapElementRef,
    enablePan,
    enableZoom,
    unavailableSeatFilter
  ])
  // end initialize

  useEffect(() => {
    if (!getMap()) return

    getMap().setMapClickCallback(mapClickHandler)
    getMap().setFeatureSelectCallback(featureSelectHandler)
    getMap().setPopupRepositionCallback(popupRepositionHandler)
    getMap().setMapScrollCallback(mapScrollHandler)
    getMap().setFeatureStyle(getFeatureStyle)
    getMap().setUnavailableSeatFilter(unavailableSeatFilter)
  })

  useEffect(() => {
    return () => {
      getMap().deregisterListeners()
    }
  }, [])

  useEffect(() => {
    if (mapObjectRef?.current) {
      mapObjectRef.current.featuresLayer
        .getSource()
        .getFeatures()
        .forEach(feature => feature.setStyle(getFeatureStyle(feature)))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatNumber])

  useEffect(() => {
    const arrowKeyHandler = e => {
      if (mapObjectRef.current && selectedFeature) {
        let seatNumber = 0
        switch (e.key) {
          case 'ArrowUp':
            seatNumber = findNearestFeature(selectedFeature, 'up')
            break
          case 'ArrowRight':
            seatNumber = findNearestFeature(selectedFeature, 'right')
            break
          case 'ArrowDown':
            seatNumber = findNearestFeature(selectedFeature, 'down')
            break
          case 'ArrowLeft':
            seatNumber = findNearestFeature(selectedFeature, 'left')
            break
          case 'Enter':
            if (isSelected) {
              onRemoveSeatClick(feature)
            } else {
              onAddSeatClick(feature)
            }
            break
          default:
            break
        }
        if (seatNumber) {
          const feature = getFeatureBySeatNumber(seatNumber)
          featureSelectHandler([feature])
          mapObjectRef.current.popupForFeature(imageExtent, feature)
        }
      }
    }

    window.addEventListener('keydown', arrowKeyHandler)

    return () => window.removeEventListener('keydown', arrowKeyHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature, mapObjectRef.current])

  useEffect(() => {
    const spaceKeyHandler = e => {
      if (mapObjectRef.current && selectedFeature && e.key === ' ') {
        onFeatureSpacebarKeydown(selectedFeature)
      }
    }

    window.addEventListener('keydown', spaceKeyHandler)

    return () => window.removeEventListener('keydown', spaceKeyHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature, mapObjectRef.current])

  useEffect(() => {
    const spaceKeyHandler = e => {
      if (mapObjectRef.current && selectedFeature && e.key === 'Tab') {
        featureSelectHandler([])
      }
    }

    window.addEventListener('keydown', spaceKeyHandler)

    return () => window.removeEventListener('keydown', spaceKeyHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature, mapObjectRef.current])

  function getAngle(origin, position) {
    const x = position[0] - origin[0]
    const y = position[1] - origin[1]
    return Math.atan2(y, x)
  }

  function getLength(origin, position) {
    return Math.sqrt(
      (position[0] - origin[0]) ** 2 + (position[1] - origin[1]) ** 2
    )
  }

  function findNearestFeature(
    feature,
    direction,
    bounds = DEFAULT_KEY_NAV_BOUNDS
  ) {
    const id = feature.get('seatNumber')
    const featureNode = seatNodes.find(node => node.id === id)
    const unitVector = getUnitVector(direction)
    const nodes = seatNodes
      .filter(node => node.id !== id)
      .map(node => {
        let angle = getAngle(featureNode.position, node.position)
        if (direction === 'left' || direction === 'right') {
          angle = Math.abs(angle)
        }
        return {
          id: node.id,
          angle,
          position: node.position,
          length: getLength(featureNode.position, node.position)
        }
      })
      .filter(
        node =>
          node.angle < unitVector + bounds && node.angle > unitVector - bounds
      )
      .sort((a, b) => a.length - b.length)

    if (nodes.length > 0) {
      return nodes[0]?.id
    } else if (bounds === DEFAULT_KEY_NAV_BOUNDS) {
      return findNearestFeature(feature, direction, Math.PI / 4) // Expand search radius
    }
  }

  function getUnitVector(direction) {
    switch (direction) {
      case 'left':
        return Math.PI
      case 'right':
        return 0
      case 'up':
        return Math.PI / 2
      case 'down':
        return Math.PI / -2
      default:
        return
    }
  }

  function getFeatureBySeatNumber(seatNumber) {
    return mapObjectRef.current.featuresLayer
      .getSource()
      .getFeatures()
      .find(feature => feature.get('seatNumber') === seatNumber)
  }

  function handleMapFocus(e) {
    if (!selectedFeature) {
      const feature = getFeatureBySeatNumber(firstNode.id)
      featureSelectHandler([feature])
      mapObjectRef.current.popupForFeature(imageExtent, feature)
      e.target.blur()
    }
  }

  function generateSeatNodes(seatData) {
    return seatData.features
      .filter(feature => {
        if (feature.properties.unavailable) {
          return false
        }
        return true
      })
      .map(feature => {
        const shape = feature.geometry.coordinates[0]
        const position = [
          shape.reduce((acc, point) => acc + point[0], 0) / shape.length,
          shape.reduce((acc, point) => acc + point[1], 0) / shape.length
        ]
        return {
          id: feature.properties.seatNumber,
          position
        }
      })
  }

  return (
    <div className="full-size relative">
      {/* seat map */}
      <div tabIndex={0} onFocus={handleMapFocus}></div>
      <h2 className="back-of-ferry">{labels.backOfFerry}</h2>
      <div ref={mapElementRef} className="map-container" />
      {/* navigation buttons */}
      {/* left side button */}
      <div className="btn-placeholder-left">
        {showLeftButton() && (
          <NavigationButton
            icon={navLeftMapIcon}
            alt="scroll to right"
            clickHandler={() => scrollMapTo('right')}
          />
        )}
      </div>
      {/* right side button */}
      <div className="btn-placeholder-right">
        {showRightButton() && (
          <NavigationButton
            icon={navRightMapIcon}
            alt="scroll to left"
            clickHandler={() => scrollMapTo('left')}
          />
        )}
      </div>
      {/* navigation indicator */}
      <MapSectionIndicator
        currentSectionIndex={tileIndex}
        sectionCount={totalTiles}
      />
    </div>
  )
}

export default ResponsiveSeatMap

/**
 * internal/private map component
 */
class CoreSeatMap {
  /**
   * ol/Map wrapper(component) for seats display in a traditional way
   *
   * @param {*} mapElement
   * @param {*} overlayElement, father of popup window
   */
  constructor(mapElement, overlayElement) {
    this.mapElement = mapElement
    this.overlayElement = overlayElement

    this.backgroundImgURL = null
    this.imageExtent = null
    this.featuresJson = null

    this.imageLayer = null
    this.featuresLayer = null
    this.overlay = null
    this.map = null
    this.resizeObserver = null
    // mapElement father
    this.mapContainerElmt = null
    // cache for deselect use on popup close
    this.selectInteraction = null
    // default sections(left, right), will be determined in init function
    // for now, we consider the map is virtually composed of horizontal tiles(aka navigation) only,
    // if the image is taller than it is wide, we'll treat it as just 1 division.
    this.virtualTilesCount = 2
    // current visible section in smaller screen
    this.virtualTileIndex = 0
    // default seat popup size to calculatie center position
    this.popupSize = []
  }

  /**
   * construc a openlayer map object with image and json data
   * @param {string} backgroundImgURL
   * @param {array} imageExtent
   * @param {string} featuresJson
   * @param {object} options define map interaction capabilities
   */
  init(backgroundImgURL, imageExtent, featuresJson, options = {}) {
    // save current extent to fit later
    this.imageExtent = imageExtent
    this.backgroundImgURL = backgroundImgURL
    this.featuresJson = featuresJson

    // start to build map assets
    const projection = new Projection({
      units: 'pixels',
      extent: imageExtent
    })

    this.imageLayer = new ImageLayer({
      source: new Static({
        url: backgroundImgURL,
        projection,
        imageExtent
      })
    })

    const featuresSource = new VectorSource({
      features: new GeoJSON().readFeatures(featuresJson)
    })

    this.featuresLayer = new VectorLayer({
      source: featuresSource,
      style: []
    })

    this.overlay = new Overlay({
      element: this.overlayElement,
      autoPan: false, // TODO: Revisit autoPan ... might not need
      autoPanAnimation: {
        duration: 250
      }
    })

    const interactOptions = {
      dragPan: !!options.enablePan,
      pinchZoom: !!options.enableZoom,
      doubleClickZoom: !!options.enableZoom,
      mouseWheelZoom: !!options.enableZoom,
      shiftDragZoom: !!options.enableZoom,
      keyboard: false
    }

    this.map = new Map({
      layers: [this.imageLayer, this.featuresLayer],
      overlays: [this.overlay],
      controls: [],
      interactions: createInteractions(interactOptions),
      target: this.mapElement,
      view: new View({
        projection,
        center: getCenter(imageExtent),
        // showFullExtent: true,
        zoom: 1.5
      })
    })
    this._addClickEventListener(imageExtent)
    this._addPointerMoveListener()

    const selectInteraction = new SelectInteraction({
      source: featuresSource,
      style: pickerSelectionStyle,
      filter: options.selectFilter
    })
    this._addSelectFeatureEventListener(selectInteraction)
    this.map.addInteraction(selectInteraction)
    this.selectInteraction = selectInteraction

    this._addPopupCloseEventListern()

    this.updateSectionCount()
    this.handleResize()
  }

  /**
   * Switch to the visible section
   *
   * @param {number} duration in milliseconds
   */
  animateToVisibleSection(duration = 300) {
    const zoom = this.calculateZoom()
    const commonOptions = { duration, zoom }
    const coordinates = [
      // one part of map
      this.calculateSectionCenter(),
      (this.imageExtent[1] + this.imageExtent[3]) / 2
    ]
    this.map.getView().animate({
      center: coordinates,
      ...commonOptions
    })
    // Invoke map scroll callback
    this.onMapScroll &&
      this.onMapScroll(this.virtualTileIndex, this.virtualTilesCount)
  }

  calculateZoom() {
    const view = this.map.getView()
    const maxResolution = view.getMaxResolution()
    const mapSize = this.map.getSize()
    const imageHeight = this.imageExtent[3]
    const mapHeight = mapSize[1]

    return Math.log2((mapHeight * maxResolution) / imageHeight)
  }

  updateSectionCount() {
    const imageWidth = this.imageExtent[2]
    const imageHeight = this.imageExtent[3]

    const mapSize = this.map.getSize()
    const mapAspectRatio = mapSize[0] / mapSize[1]
    const imageAspectRatio = imageWidth / imageHeight
    // This is illegal which cause app crash!
    // Once(no reoccurance now) happened in switching browser from desktop to mobile device mode.
    // So, check to stop calculation for safety case.
    if (mapAspectRatio === 0) return

    const isMobile = window.innerWidth < DESKTOP_WIDTH_MIN
    this.virtualTilesCount = isMobile
      ? Math.ceil(imageAspectRatio / mapAspectRatio)
      : 1
    if (this.virtualTileIndex > this.virtualTilesCount - 1) {
      this.virtualTileIndex = this.virtualTilesCount - 1
    }
  }

  handleResize() {
    // Initial animateToVisibleSection is to set zoom level, second is to fit to correct section
    if (this.virtualTilesCount === 1) {
      this.map.getView().fit(this.imageExtent)
    } else {
      this.animateToVisibleSection(0)
      setTimeout(() => {
        this.animateToVisibleSection(0)
      }, 1)
    }
  }

  /**
   * for switching map button use in smaller screen
   *
   * @param {string} direction left or right;
   */
  scrollVisibleSectionMobile(direction) {
    const increment = { left: 1, right: -1 }
    // switch to next tile
    this.virtualTileIndex += increment[direction]
    // reset to initial state
    if (
      this.virtualTileIndex >= this.virtualTilesCount ||
      this.virtualTileIndex < 0
    ) {
      this.virtualTileIndex = 0
    }

    this.animateToVisibleSection()

    // notify popup to close even if its hidden
    this.overlayElement.dispatchEvent(new Event('close'))
  }

  calculateSectionCenter() {
    const view = this.map.getView()
    const mapSize = this.map.getSize()
    const visible = view.calculateExtent(mapSize)
    const visibleWidth = visible[2] - visible[0]
    const imageWidth = this.imageExtent[2]

    let centerX
    if (this.virtualTilesCount === 1) {
      centerX = imageWidth / 2
    } else if (this.virtualTileIndex === this.virtualTilesCount - 1) {
      centerX = imageWidth - visibleWidth / 2
    } else {
      centerX =
        visibleWidth / 2 +
        (this.virtualTileIndex / this.virtualTilesCount) * imageWidth
    }

    return centerX
  }

  /**
   * update map size smoothly with its container resized
   * @param {string} selector map container selector, default use : `.seat-picker-ol-container`
   */
  addMapContainerResizeListener(selector = '.seat-picker-ol-container') {
    if (this.resizeObserver) {
      return this.resizeObserver.observe(this.mapContainerElmt)
    }
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(() => {
        this.map.getView().fit(this.imageExtent)
      })
      setTimeout(() => {
        this.updateSectionCount()
        this.handleResize()
      }, 0)
    })
    const mapContainerElmt = document.querySelector(selector)
    resizeObserver.observe(mapContainerElmt)
    // cache for later unregister
    this.mapContainerElmt = mapContainerElmt
    this.resizeObserver = resizeObserver
  }

  popupForFeature(imageExtent, feature) {
    // vacant seat selected
    const [x, y] = feature.getGeometry().getCoordinates()[0][0]
    const clickedRightHalf = x > imageExtent[2] / 2
    const clickedBottomHalf = y < imageExtent[3] / 2
    const xOffset = clickedRightHalf ? 20 : 60
    const yOffset = clickedBottomHalf ? 0 : -15
    const directions = { left: clickedRightHalf, above: clickedBottomHalf }
    // show popup
    this.overlay.setPosition([x + xOffset, y + yOffset])
    // execute callback if defined: save the position
    this._correctPopupPositionInMobileScreen(directions)
    this.onPopupMove && this.onPopupMove(directions)
  }

  deregisterListeners() {
    this.resizeObserver.unobserve(this.mapContainerElmt)
  }

  _addSelectFeatureEventListener(selectInteraction) {
    selectInteraction.on('select', event => {
      if (event.selected?.length) {
        const feature = event.selected[0]
        // only polygon shape dispatch event
        if (feature.getGeometry() instanceof Polygon) {
          // execute callback if defined
          this.onFeatureSelect && this.onFeatureSelect([feature])
        }
      }
    })
  }

  _selectMapBlank() {
    // execute callback if defined
    this.onMapClick && this.onMapClick()
    this.overlay.setPosition(undefined)
  }

  _addClickEventListener(imageExtent) {
    this.map.on('singleclick', event => {
      const features = this.map.getFeaturesAtPixel(event.pixel)
      // on blank
      if (!features.length) return this._selectMapBlank()
      // maybe unavailable seat
      const unavailable = this.unavailableSeatFilter(features[0])
      if (unavailable) return this._selectMapBlank()
      this.popupForFeature(imageExtent, features[0])
    })
  }

  _usePointerCursor() {
    this.map.getViewport().style.cursor = 'pointer'
  }

  _useDefaultCursor() {
    this.map.getViewport().style.cursor = ''
  }

  _addPointerMoveListener() {
    this.map.on('pointermove', event => {
      const pixel = this.map.getEventPixel(event.originalEvent)
      const features = this.map.getFeaturesAtPixel(pixel)
      if (!features.length) return this._useDefaultCursor()

      const unavailable = this.unavailableSeatFilter(features[0])
      if (unavailable) return this._useDefaultCursor()

      const hit = this.map.hasFeatureAtPixel(pixel)
      const isMap = event.originalEvent.target.tagName === 'CANVAS' // Might be an overlay
      hit && isMap ? this._usePointerCursor() : this._useDefaultCursor()
    })
  }

  /**
   * listen up popup close event to hide overlay and deselect feature
   */
  _addPopupCloseEventListern() {
    this.overlayElement.addEventListener('close', event => {
      // simulate map click in blank to clear selected feature
      const mapClickEvent = new MapBrowserEvent('singleclick', this.map, event)
      this.map.dispatchEvent(mapClickEvent)
      this.selectInteraction.handleEvent(mapClickEvent)
    })
  }

  /**
   * Check screen size to center the popup in mobile screen
   *
   * @param {object} directions if need to reposition
   * @param {string} maxMobileScreenWidth max mobile screen size default use 415px(iphon8+)
   */
  _correctPopupPositionInMobileScreen(
    directions,
    maxMobileScreenWidth = '415px'
  ) {
    const mobileScreenQuery = window.matchMedia(
      `(max-width: ${maxMobileScreenWidth})`
    )
    if (!mobileScreenQuery.matches) return // beyond this screen size, no centre behavior
    // reset style
    directions.left = false
    directions.above = false
    // get coordinate of screen center
    const bounds = this.mapElement.getBoundingClientRect()
    const popupLeftTopCornerX = bounds.width / 2 - this.popupSize[0] / 2
    const popupLeftTopCornerY = bounds.height / 2 - this.popupSize[1] / 2
    const popupLeftTopCornerPosition = [
      popupLeftTopCornerX,
      popupLeftTopCornerY
    ]
    const mapCenterCoordinate = this.map.getCoordinateFromPixel(
      popupLeftTopCornerPosition
    )
    // place to center
    this.overlay.setPosition(mapCenterCoordinate)
  }

  setFeatureSelectCallback(callback) {
    this.onFeatureSelect = callback
  }

  setFeatureStyle(callback) {
    this.featuresLayer.setStyle(callback)
  }

  setMapClickCallback(callback) {
    this.onMapClick = callback
  }

  setPopupRepositionCallback(callback) {
    this.onPopupMove = callback
  }

  setMapScrollCallback(callback) {
    this.onMapScroll = callback
  }

  setUnavailableSeatFilter(callback) {
    this.unavailableSeatFilter = callback
  }

  setVirtualTilesCount(total) {
    this.virtualTilesCount = total
  }

  /**
   * setup popup window size
   * @param {array} widthAndHeight pixel values, like: [200, 234]
   */
  setPopupSize(widthAndHeight) {
    this.popupSize = widthAndHeight
  }
}
