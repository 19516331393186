import {
  normalizeLabelWithCount,
  getPassengerLabelKeysFromCode
} from '../../configuration/utilities'
import { selectFerryRouteSelectionsState } from '../ferryRouteSelections/selectors'
import { selectPassengerSelections } from '../passengerSelections/selectors'
import { selectDeparturePets } from '../passengerSelections/selectors'
import { selectReturnPets } from '../passengerSelections/selectors'
import {
  selectDepartureVehicle,
  selectReturnVehicle
} from '../vehicleSelections/selectors'
import selectVehicleFormType from '../selectors/selectVehicleFormType'
import { selectActiveCrossings } from '../activeCrossings/selectors'
import { selectCrossingData } from '../crossings/selectors'
import { createSelector } from 'reselect'
import { selectLabels } from '../configuration/selectors'
import { selectPaymentConfirmationData } from '../paymentConfirmation/selectors'
import { selectActiveAccommodations } from '../activeAccommodations/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'
import { selectLanguage } from '../session/selectors'
import { get } from 'lodash'

const state = state => state

export const selectPricingBreakdown = createSelector(state, allState => {
  const crossingState = selectActiveCrossings(allState)
  const labels = selectLabels(allState)
  const language = selectLanguage(allState)
  const paymentConfirmationData = selectPaymentConfirmationData(allState)
  const activeModifyBooking = selectActiveModifyBooking(allState)
  const departurePets = selectDeparturePets(allState)
  const returnPets = selectReturnPets(allState)

  if (paymentConfirmationData) {
    return {
      total: paymentConfirmationData?.amount || 0
    }
  }

  const activeFerryRoutes = selectFerryRouteSelectionsState(allState)
  /*
   * Price priorities are paymentConfirmation.data // this is a finalized and paid trip
   * bookingData.data // this is either prior to payment OR on modification,
   * then Active crossings
   * otherwise we make an estimate  (this is only needed on two steps., passengers and vehicles.)
   * */
  const activeAccommodations = selectActiveAccommodations(allState)
  const breakdown = Object.entries(crossingState).reduce(
    (obj, [directionKey, direction]) => {
      const matchingRouteData =
        activeFerryRoutes[
          directionKey === 'departure' ? 'departureRoute' : 'returnRoute'
        ]
      const directionTotal = get(
        direction,
        'bookingPrice.bookingPrice.totalPrice',
        0
      )

      const discount = get(direction, 'bookingPrice.bookingPrice.discount', 0)
      const totalBeforeDiscount = +directionTotal + +discount

      const miscFees = get(direction, 'bookingPrice.bookingPrice.fees') || []

      obj[directionKey].total = directionTotal
      obj[directionKey].miscFees = miscFees
      obj[directionKey].discount = discount
      obj[directionKey].totalBeforeDiscount = totalBeforeDiscount

      const passengers = get(
        direction,
        'bookingPrice.bookingPrice.resources.passengers'
      )
      const passengerTaxesAndLevies = get(
        direction,
        'bookingPrice.bookingPrice.resources.passengerTaxesAndLevies',
        {}
      )
      obj[directionKey].passengerTaxesAndLevies = passengerTaxesAndLevies

      if (passengers && passengers.length) {
        obj[directionKey].passengers = passengers.reduce(
          (passengerObj, passengerType) => {
            const matchingPassengersDetails =
              matchingRouteData && matchingRouteData.passengerTypes
                ? matchingRouteData.passengerTypes
                : []

            const matchingPassenger = matchingPassengersDetails.find(
              type => type.type === passengerType.type
            )

            const passengerLabels = getPassengerLabelKeysFromCode(
              matchingPassenger?.code
            )

            if (!passengerObj[passengerType.type]) {
              passengerObj[passengerType.type] = {
                ...passengerType,
                displayName:
                  normalizeLabelWithCount({
                    labelKey: passengerLabels.default,
                    pluralLabelKey: passengerLabels.plural,
                    language,
                    count: 1,
                    showCountWhenOne: true,
                    labels
                  }) || labels.passenger,
                count: 1
              }
            } else {
              const newCount = passengerObj[passengerType.type].count + 1

              passengerObj[passengerType.type] = {
                ...passengerObj[passengerType.type],
                count: newCount,
                displayName: normalizeLabelWithCount({
                  labelKey: passengerLabels.default,
                  pluralLabelKey: passengerLabels.plural,
                  language,
                  count: newCount,
                  showCountWhenOne: true,
                  labels
                })
              }
            }
            return passengerObj
          },
          {}
        )
      }

      const vehicles = get(
        direction,
        'bookingPrice.bookingPrice.resources.vehicle'
      )
      const vehicleTaxesAndLevies = get(
        direction,
        'bookingPrice.bookingPrice.resources.vehicleTaxesAndLevies',
        {}
      )
      obj[directionKey].vehicleTaxesAndLevies = vehicleTaxesAndLevies

      if (vehicles && vehicles.length) {
        obj[directionKey].vehicles = reduceResource(vehicles)
      }

      const accommodations = get(
        direction,
        'bookingPrice.bookingPrice.resources.accommodations'
      )
      obj[directionKey].accommodationTaxesAndLevies = {
        additionalTaxesAndLevies: 0
      }

      if (activeAccommodations?.[directionKey]) {
        obj[directionKey].accommodations = activeAccommodations?.[directionKey]

        for (const accommodation of Object.values(
          activeAccommodations?.[directionKey]
        )) {
          if (!accommodation?.waitlist) {
            const taxes =
              +accommodation?.count * +accommodation?.details?.tax || 0

            obj[
              directionKey
            ].accommodationTaxesAndLevies.additionalTaxesAndLevies += taxes

            obj[directionKey].total = +obj[directionKey].total + taxes
            // add accommodation to discount
            obj[directionKey].totalBeforeDiscount =
              +obj[directionKey].totalBeforeDiscount + taxes

            obj[directionKey].total =
              +obj[directionKey].total +
                +accommodation?.count * +accommodation?.details?.price || 0
            obj[directionKey].totalBeforeDiscount =
              +obj[directionKey].totalBeforeDiscount +
                +accommodation?.count * +accommodation?.details?.price || 0
          }
        }
      }

      if (accommodations && accommodations.length) {
        obj[directionKey].accommodations = reduceResource(accommodations)
      }

      const reservedSeats = direction?.reservedSeats || []
      if (reservedSeats.length > 0) {
        const seatResourceCode = 'RDS'
        const details = direction.resources.find(
          res => res.resourceCode === seatResourceCode
        )
        obj[directionKey].accommodations[seatResourceCode] = {
          count: reservedSeats.length,
          details,
          displayName: labels.reservedSeat,
          displayTitlePlural: labels.reservedSeats
        }
      }

      obj.total += +obj[directionKey].total
      obj.discount += +obj[directionKey].discount
      obj.totalBeforeDiscount += +obj[directionKey].totalBeforeDiscount

      return obj
    },
    {
      total: 0,
      discount: 0,
      totalBeforeDiscount: 0,
      departure: {},
      return: {}
    }
  )
  // if we have everything we need, no need to build estimates.
  if (
    breakdown?.departure?.passengers &&
    (activeFerryRoutes.activeRouteForm !== 'RT' ||
      breakdown?.return?.passengers)
  ) {
    if (activeModifyBooking?.paid) {
      breakdown.paid = Number(activeModifyBooking.paid)
      breakdown.totalAfterPaid =
        Number(breakdown.total) - Number(activeModifyBooking.paid)
    }
    return breakdown
  }

  const crossingsData = selectCrossingData(allState)

  if (
    !Object.keys(crossingsData).length ||
    (crossingsData.departure && crossingsData.departure.loading) ||
    (crossingsData.return && crossingsData.return.loading)
  ) {
    return {
      total: 0,
      loading: true
    }
  }

  const passengerSelections = selectPassengerSelections(allState)
  const departureVehicleSelections = selectDepartureVehicle(allState)
  const returnVehicleSelections = selectReturnVehicle(allState)
  const vehicleFormType = selectVehicleFormType(allState)
  // if we have yet to select a departure crossing build a departure estimate
  if (!crossingState.departure) {
    breakdown.departure.isEstimate = true

    if (
      crossingsData &&
      crossingsData.departure &&
      crossingsData.departure.passengerBestRates
    ) {
      const directionEstimate = renderDirectionEstimate({
        crossingsData: crossingsData.departure,
        passengerSelections: passengerSelections.departure,
        activeFerryRoutes: activeFerryRoutes['departureRoute'],
        vehicle:
          crossingsData?.departure?.resourceBestRates?.[
            departureVehicleSelections?.vehicleType?.resourceCode
          ],
        labels,
        language,
        pets: departurePets
      })
      breakdown.departure = {
        ...breakdown.departure,
        ...directionEstimate
      }

      if (directionEstimate?.total) {
        breakdown.total += directionEstimate?.total
      }
    }
  }

  // if we have yet to select a return crossing and are booking a return trip, build a return estimate
  if (!crossingState.return && activeFerryRoutes.activeRouteForm === 'RT') {
    breakdown.return.isEstimate = true

    const selectedVehicle =
      vehicleFormType === 'VEH-DUP' || vehicleFormType === 'VELO'
        ? crossingsData?.return?.resourceBestRates?.[
            departureVehicleSelections?.vehicleType?.resourceCode
          ]
        : crossingsData?.return?.resourceBestRates?.[
            returnVehicleSelections?.vehicleType?.resourceCode
          ]
    if (
      crossingsData &&
      crossingsData.return &&
      crossingsData.return.passengerBestRates
    ) {
      const directionEstimate = renderDirectionEstimate({
        crossingsData: crossingsData.return,
        passengerSelections: passengerSelections.duplicatePassengerQuantities
          ? passengerSelections.departure
          : passengerSelections.return,
        activeFerryRoutes: activeFerryRoutes['returnRoute'],
        vehicle: selectedVehicle,
        labels,
        language,
        pets: returnPets
      })
      breakdown.return = {
        ...breakdown.return,
        ...directionEstimate,
        totalBeforeDiscount: directionEstimate.total
      }

      if (directionEstimate?.total) {
        breakdown.total += directionEstimate?.total
        breakdown.totalBeforeDiscount += +directionEstimate.total
      }
    }
  }

  if (activeModifyBooking?.paid) {
    breakdown.paid = Number(activeModifyBooking.paid)
    breakdown.totalAfterPaid =
      Number(breakdown.total) - Number(activeModifyBooking.paid)
  }

  // if we have yet to select a return crossing AND we have selected a return trip build an estimate
  return breakdown
})

function renderDirectionEstimate({
  crossingsData,
  passengerSelections,
  activeFerryRoutes,
  vehicle,
  labels,
  language,
  pets
}) {
  const tripEstimate = {
    total: 0
  }

  if (
    passengerSelections &&
    activeFerryRoutes?.passengerTypes?.length &&
    crossingsData?.passengerBestRates
  ) {
    tripEstimate.passengers = Object.entries(passengerSelections).reduce(
      (obj, [passengerType, passengerCount]) => {
        if (passengerCount === 0) {
          return obj
        }
        const matchingPassengerType = activeFerryRoutes.passengerTypes.find(
          type => type.code === passengerType
        )

        if (matchingPassengerType?.type) {
          const passengerLabels = getPassengerLabelKeysFromCode(
            matchingPassengerType.code
          )
          const normalizedLabelWithCount = normalizeLabelWithCount({
            labelKey: passengerLabels.default,
            pluralLabelKey: passengerLabels.plural,
            language,
            count: passengerCount,
            showCountWhenOne: true,
            labels
          })
          const bestRate = crossingsData.passengerBestRates
          obj[matchingPassengerType.type] = {
            ...matchingPassengerType,
            count: passengerCount,
            price: bestRate?.[matchingPassengerType.type] || 0,
            description: labels.passengers,
            displayName:
              normalizedLabelWithCount || labels[matchingPassengerType.code]
          }
          tripEstimate.total +=
            +passengerCount * bestRate?.[matchingPassengerType.type]
        }

        return obj
      },
      {}
    )
    if (vehicle) {
      tripEstimate.vehicles = {
        [vehicle.resourceCode]: { ...vehicle, count: 1 }
      }
      tripEstimate.total += +vehicle.price
    }

    if (pets) {
      tripEstimate.passengers.PET = {
        resourceCode: 'PET',
        price: 0,
        description: 'Pets',
        discount: 0,
        netPrice: 0,
        netDiscount: 0,
        displayName:
          normalizeLabelWithCount({
            labelKey: 'petLabel',
            pluralLabelKey: 'pets',
            language,
            count: pets,
            showCountWhenOne: true,
            labels
          }) || labels.petLabel,
        count: 1
      }
    }
  }

  return tripEstimate
}

function reduceResource(resources) {
  return resources.reduce((obj, resource) => {
    if (!obj[resource.resourceCode]) {
      obj[resource.resourceCode] = {
        ...resource,
        count: 1
      }
    } else {
      obj[resource.resourceCode].count += 1
    }

    return obj
  }, {})
}
