import React, { useEffect } from 'react'
import { bool, func, shape } from 'prop-types'

const SamePassengersToggle = ({ isSamePassengers, handleToggle, labels }) => {
  useEffect(() => {
    const spaceKeyHandler = event => {
      if (event.key === ' ') {
        handleToggle(event.target.innerText === labels.yes)
      }
    }

    window.addEventListener('keydown', spaceKeyHandler)
    return () => window.removeEventListener('keydown', spaceKeyHandler)
  }, [handleToggle, labels.yes])

  return (
    <div className="passenger-quantities__duplicate-form" role="radiogroup">
      <h5 className="theme-font-header" id="same-passengers-header">
        {labels.passOnSameJourneyMsg}
      </h5>

      <div className="passenger-quantities__duplicate-form-toggles">
        <div
          className={`passenger-quantities__duplicate-form-toggle enable${
            isSamePassengers ? ' active' : ''
          }`}
          onClick={() => handleToggle(true)}
          tabIndex={0}
          aria-label={`${labels.passOnSameJourneyMsg} ${labels.yes}`}
          role="radio"
          aria-checked={isSamePassengers}
        >
          {labels.yes}
        </div>
        <div
          className={`passenger-quantities__duplicate-form-toggle disable${
            !isSamePassengers ? ' active' : ''
          }`}
          onClick={() => handleToggle(false)}
          tabIndex={0}
          aria-label={`${labels.passOnSameJourneyMsg} ${labels.no}`}
          role="radio"
          aria-checked={!isSamePassengers}
        >
          {labels.no}
        </div>
      </div>
    </div>
  )
}

SamePassengersToggle.propTypes = {
  isSamePassengers: bool,
  handleToggle: func.isRequired,
  labels: shape({})
}

export default SamePassengersToggle
