import React, { Fragment } from 'react'
import { arrayOf, bool, func, string } from 'prop-types'
import { useSelector } from 'react-redux'
import yn from 'yn'
import { isEmpty } from 'lodash'
import SvgIcon from '../SvgIcon/SvgIcon'
import { appRoutes, stepKeys } from '../../configuration/constants'
import { normalizeLabelWithCount } from '../../configuration/utilities'
import { buildStepsFromKeys } from '../../configuration/utilities'
import { selectLabels } from '../../redux/configuration/selectors'
import { selectLanguage } from '../../redux/session/selectors'
import {
  selectModifyFlowType,
  selectIsVehicleEditable
} from '../../redux/modifyBooking/selectors'
import { isCurrentPath } from '../../utilities/browser/comparePaths'
import './Stepper.scss'

import { selectUserSelections } from '../../redux/userSelections/selectors'

const Stepper = ({ handleRouteChange, isDisabled, postStepperPaths }) => {
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)
  const state = useSelector(state => state)
  const modifyFlowType = useSelector(selectModifyFlowType)
  const vehicleEditable = useSelector(selectIsVehicleEditable)

  const userSelections = useSelector(selectUserSelections)

  const steps = buildStepsFromKeys(
    stepKeys,
    labels,
    modifyFlowType,
    vehicleEditable,
    state,
    userSelections
  )

  if (
    (isCurrentPath(appRoutes.ferryRoutes.pathname) &&
      yn(process.env.REACT_APP_HIDE_STEPPER_ON_ROUTE_SELECTION)) ||
    isCurrentPath(appRoutes.payment.pathname)
  ) {
    return null
  }

  let allStepsCompleted = false
  const stepFromPath = Object.values(steps).find(step =>
    isCurrentPath(step.pathname)
  )

  if (postStepperPaths.find(path => isCurrentPath(path))) {
    allStepsCompleted = true
  }

  const activeStepNumber = allStepsCompleted
    ? stepKeys.length
    : (stepFromPath && stepFromPath.stepNumber) || 0

  const totalSteps = Object.keys(steps).length

  const currentStep = Object.values(steps).find(
    step => step.stepNumber === activeStepNumber
  )

  const renderStepButton = ({
    isActive,
    isCompleted,
    isDisabled,
    pathname,
    label,
    step: { icon = false, stepNumber },
    handleRouteChange,
    renderDivider,
    key
  }) => (
    <Fragment key={key}>
      <button
        key={'stepper-button-' + pathname}
        className={`theme-title-variant-nine stepper__button btn${
          isActive ? ' active' : isCompleted ? ' complete' : ''
        }`}
        disabled={isDisabled}
        value={pathname}
        onClick={() => {
          if (pathname !== '/book/payment') handleRouteChange(pathname)
        }}
        aria-label={label}
        aria-current={isActive ? 'page' : undefined}
      >
        {icon && (
          <SvgIcon
            className="stepper__button-icon"
            type={icon.type}
            fill={isActive || isCompleted ? icon.active : icon.default}
          />
        )}
        {label}
      </button>
      {renderDivider && (
        <i
          key={'stepper-divider-' + pathname + '-' + stepNumber}
          className="stepper__divider material-icons"
        >
          arrow_right_alt
        </i>
      )}
    </Fragment>
  )

  return (
    <>
      {!allStepsCompleted && (
        <div className="mobile-stepper">
          <p>
            {activeStepNumber + 1} / {totalSteps}{' '}
            {currentStep && currentStep.defaultLabel}
          </p>
          <div className="stepper-dots">
            {Object.entries(steps).map(([stepKey, step], stepIndex) => {
              const isActive = activeStepNumber === +step.stepNumber
              const isPast = activeStepNumber > +step.stepNumber

              return (
                <button
                  onClick={() => handleRouteChange(step.pathname)}
                  disabled={
                    (!isPast && !isActive) || isDisabled || !step.pathname
                  }
                  key={`${step.defaultLabel}-${stepIndex}`}
                  className={`stepper-dot ${isActive ? 'active' : ''} ${
                    isPast ? 'complete' : ''
                  }`}
                  aria-label={labels.moreSteps || 'More Steps'}
                />
              )
            })}
          </div>
        </div>
      )}

      <div className="stepper" aria-label="breadcrumb">
        <div className="u-container">
          <div className="stepper__buttons">
            {steps &&
              Object.entries(steps).map(([stepKey, step]) => {
                const isActive =
                  activeStepNumber === +step.stepNumber || allStepsCompleted
                const isComplete =
                  activeStepNumber > +step.stepNumber && step.pathname
                const {
                  pathname,
                  labelKey,
                  pluralLabelKey,
                  ...restStepDetails
                } = step
                const renderCompletedStepLabel =
                  !isEmpty(labels) &&
                  isComplete &&
                  step?.renderCompletedStepLabel

                const disabled = Boolean(
                  (!isComplete && !isActive) || isDisabled || !step.pathname
                )

                return renderStepButton({
                  key: stepKey,
                  isActive: isActive,
                  isCompleted: Boolean(isComplete),
                  isDisabled: disabled,
                  step: restStepDetails,
                  pathname: pathname,
                  label: renderCompletedStepLabel
                    ? renderCompletedStepLabel({
                        state,
                        userSelections,
                        labels
                      })
                    : normalizeLabelWithCount({
                        labelKey,
                        pluralLabelKey,
                        language,
                        count: 1,
                        labels
                      }),
                  handleRouteChange: handleRouteChange,
                  altIconLabel: labels.icon,
                  renderDivider:
                    yn(process.env.REACT_APP_COMPONENT_STEPPER_DIVIDERS) &&
                    +step.stepNumber < Object.keys(steps).length - 1
                })
              })}
          </div>
        </div>
      </div>
    </>
  )
}

Stepper.propTypes = {
  handleRouteChange: func.isRequired,
  isDisabled: bool,
  // paths that make all steps active & complete
  postStepperPaths: arrayOf(string).isRequired
}

Stepper.defaultProps = {
  isDisabled: false
}

export default Stepper
