import React, { useState } from 'react'
import PropTypes, { func, shape, string, bool } from 'prop-types'
import LoadingDots from '../LoadingDots/LoadingDots'

import './ValidatedPromoInput.scss'

import { ReactComponent as DeleteIcon } from '../../assets/icons/close.svg'

const ValidatedPromoInput = ({
  labels,
  handleBlur,
  submitValidation,
  clearValidation,
  code,
  valid,
  loading,
  disabled,
  refs
}) => {
  // DEFAULT_OPEN flag has a top priority, then fallback to `valid` which is null initially.
  const [inputValue, setInputValue] = useState(code || '')

  const validate = () => {
    if (valid === false) {
      setInputValue('')
      clearValidation()
    } else if (!disabled && !loading) {
      submitValidation(inputValue)
        .then(isValid => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'promo_code_validation',
            validated: isValid,
            promo_code: inputValue
          })
        })
        .catch(error => {
          console.error('Error during validation:', error)
        })
    }
  }

  const handleInputChange = evt => {
    if (valid !== null) {
      clearValidation()
    }
    setInputValue(evt.target.value)
  }

  const clearPromo = () => {
    clearValidation()
    setInputValue('')
  }

  const onBlur = () => {
    handleBlur(inputValue)
  }

  const inputClass = inputValue.length
    ? 'promo-input__field active'
    : 'promo-input__field'

  let buttonClass = 'btn btn-primary'
  let buttonContents = labels.validate

  if (loading) {
    buttonContents = (
      <div className="loading-container">
        <LoadingDots color="white" />
      </div>
    )
  } else if (valid === true) {
    buttonClass += ' btn-valid'
    buttonContents = labels.promoCodeValid || '✓'
  } else if (valid === false) {
    buttonClass += ' btn-invalid'
    buttonContents = labels.validate || '✗'
  }

  return (
    <div className="promo-input">
      <div>
        <div className="promo-input__input-container">
          <div className="promo-input__label">
            <p>{labels.promoCode}</p>
          </div>
          {valid === null ? (
            <input
              className={inputClass}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={onBlur}
              placeholder={labels.promoCodeCta}
              disabled={disabled}
              ref={refs}
            />
          ) : (
            <div className="promo-input__button-container">
              <button
                className={`promo-input__button ${valid ? 'valid' : 'invalid'}`}
                onClick={clearPromo}
                ref={refs}
              >
                <span>{inputValue}</span>
                <DeleteIcon className="promo-input__button-icon" />
              </button>
            </div>
          )}

          <button
            disabled={!inputValue?.length}
            className={`submit-btn ${buttonClass}`}
            onClick={validate}
          >
            {buttonContents}
          </button>
        </div>
      </div>
      {valid === false ? (
        <span className="promo-input__msg-invalid">
          {labels.promoCodeErrorMsg}
        </span>
      ) : null}
    </div>
  )
}

ValidatedPromoInput.propTypes = {
  labels: shape().isRequired,
  handleBlur: func.isRequired,
  submitValidation: func.isRequired,
  clearValidation: func.isRequired,
  code: string.isRequired,
  valid: bool,
  loading: bool,
  disabled: bool,
  refs: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default ValidatedPromoInput
