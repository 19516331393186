import React, { useEffect } from 'react'

import yesIcon from '../../../assets/icons/seat-selected-symbol.svg'
import noIcon from '../../../assets/icons/no-icon.svg'
import './ChooseSeatPopup.css'

const ChooseSeatPopup = React.forwardRef((props, ref) => {
  const {
    feature,
    renderOnLeft,
    renderAbove,
    isSelected,
    onAddSeatClick,
    onRemoveSeatClick,
    labels,
    onCloseHandler
  } = props

  useEffect(() => {
    if (ref?.current) {
      const timer = setTimeout(() => {
        ref.current.focus()
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [ref])
  if (!feature) return null // nothing to render
  const seatNumber = feature.get('seatNumber') || '????'
  const wheelchair = feature.get('wheelchair')
  const acOutlet = feature.get('acOutlet')
  // const aisleSeat = feature.get('aisleSeat')
  // const windowSeat = feature.get('windowSeat')
  // const seatStorage = feature.get('seatStorage')
  // const legRoom = feature.get('legRoom')

  const bToYesNo = v =>
    v ? (
      <img src={yesIcon} className="yes-icon" alt="Yes" role="none" />
    ) : (
      <img src={noIcon} className="no-icon" alt="No" role="none" />
    )

  const seatNumberDisplay = seatNumber ? `${seatNumber}` : ''
  const popupLeftClass = renderOnLeft ? 'render-left' : ''
  const popupRightClass = renderAbove ? 'render-above' : ''
  const popupEndClass = `choose-seat ${popupLeftClass} ${popupRightClass}`

  // use ref to get current popup element
  // this would make sure to get the top popup overlay
  // especially in case of picker co-exists with planner
  // other solution is to pass a selector to ChooseSeatPopup
  return (
    <div
      className={popupEndClass}
      ref={ref}
      role="dialog"
      aria-modal="true"
      aria-labelledby="choose-seat-title wheelchair-info ac-outlet-info choose-seat-btn"
      tabIndex={-1}
    >
      {/* header */}
      <div className="seat-title-head">
        <h3
          id="choose-seat-title"
          className="choose-seat-title"
        >{`${labels.seat} ${seatNumberDisplay}`}</h3>
        <button
          type="button"
          className="close-button"
          onClick={onCloseHandler}
          aria-label={labels.close}
        >
          <span aria-hidden="true">X</span>
        </button>
      </div>
      {/* body */}
      <div id="choose-seat-body" className="popup-body">
        {/**
         <div className="choose-seat-info">
         {bToYesNo(aisleSeat)} <span>{labels.aisleSeat}</span>
         </div>
         <div className="choose-seat-info">
         {bToYesNo(windowSeat)} <span>{labels.windowSeat}</span>
         </div>
         **/}
        <div id="wheelchair-info" className="choose-seat-info">
          <span aria-hidden="true">{bToYesNo(wheelchair)}</span>
          <span>{labels.wheelchairAccessible}</span>
        </div>
        <div id="ac-outlet-info" className="choose-seat-info">
          <span aria-hidden="true">{bToYesNo(acOutlet)}</span>
          <span>{labels.acOutlet}</span>
        </div>
      </div>
      {/* footer */}
      <div className="footer-row" aria-labelledby="choose-seat-btn">
        {isSelected ? (
          <button
            className="choose-seat-btn"
            id="choose-seat-btn"
            aria-label={labels.removeSeat}
            onClick={onRemoveSeatClick}
          >
            {labels.removeSeat}
          </button>
        ) : (
          <button
            className="choose-seat-btn"
            id="choose-seat-btn"
            aria-label={labels.addSeat}
            onClick={onAddSeatClick}
          >
            {labels.addSeat}
          </button>
        )}
      </div>
    </div>
  )
})

export default ChooseSeatPopup
