import React from 'react'
import ReactModal from 'react-modal'
import { bool, func, string, element, oneOfType } from 'prop-types'

const Modal = ({
  isOpen,
  onRequestClose,
  children,
  overlayClassName,
  className,
  contentLabel,
  backgroundAltLabel
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={`modal-overlay ${
        overlayClassName ? overlayClassName : ''
      }`}
      className={`modal ${className ? className : ''}`}
      contentLabel={contentLabel ? contentLabel : ''}
      appElement={document.getElementById('ferries__create-reservation')}
      bodyOpenClassName={'modal--open'}
      aria={{
        label: backgroundAltLabel
      }}
    >
      {children}
    </ReactModal>
  )
}

Modal.defaultProps = {
  onRequestClose: () => null
}

Modal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  children: element.isRequired,
  overlayClassName: string,
  className: string,
  contentLabel: oneOfType([string, bool]),
  backgroundAltLabel: string
}

export default Modal
