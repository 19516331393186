import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  array,
  string,
  func,
  bool,
  shape,
  element,
  oneOfType,
  arrayOf,
  node
} from 'prop-types'
import { isEmpty } from 'lodash'

import LoginFromStep from '../LoginFromStep/LoginFromStep'
import BrandLoader from '../BrandLoader/BrandLoader'

import { appRoutes } from '../../configuration/constants'
import { selectPathToContinueTo } from '../../redux/modal/selectors'
import { selectLoginSuccess } from '../../redux/user/selectors'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'
import { clearPathname, continueToPathname } from '../../redux/modal/actions'

const FerryRoutesFormsContainer = ({
  customerAccount,
  travelAgentAccount,
  crossingsLoading,
  labels,
  openModal,
  renderFormToggles,
  routeFormElement,
  routeMap,
  handleRouteChange
}) => {
  const dispatch = useDispatch()
  const pathToContinueTo = useSelector(selectPathToContinueTo)
  const loginSuccess = useSelector(selectLoginSuccess)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)

  useEffect(() => {
    if (pathToContinueTo && loginSuccess) {
      handleRouteChange(pathToContinueTo)
    }
  }, [pathToContinueTo, loginSuccess, handleRouteChange])

  // Effect runs on component unmount - clear login continue pathname
  //   in case the user was not logged in successfully after clicking link here
  // TODO: Come up with more stable approach for the "redirect after action" paradigm
  useEffect(() => {
    return () => {
      dispatch(clearPathname())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="ferry-route-forms" className="ferry-routes__forms">
      <div
        className="ferry-routes__map u-bg-image"
        style={{ backgroundImage: 'url(' + routeMap + ')' }}
      >
        <img
          className="u-remove-element"
          src={routeMap}
          alt={labels.routeMap}
          role="none"
        />
      </div>
      <div className="ferry-routes__form">
        <div className="ferry-routes__form-container">
          <div className="ferry-routes__form-content">
            {!customerAccount && !travelAgentAccount && (
              <div>
                <LoginFromStep
                  openModal={openModal}
                  labels={labels}
                  loginLabel={`${labels.loginAccountMsg2}.`}
                  onLoginClick={() => {
                    dispatch(continueToPathname(appRoutes.account.pathname))
                  }}
                />
                <div className="ferry-routes__form-divider">
                  <div className="ferry-routes__form-divider-line" />
                  <div className="ferry-routes__form-divider-text">
                    {labels.or}
                  </div>
                  <div className="ferry-routes__form-divider-line" />
                </div>
              </div>
            )}

            <h2 className="theme-title-variant-two">
              {activeModifyBooking
                ? labels.bookingSelectionHeaderModify
                : labels.bookingSelectionHeader}
            </h2>

            {!isEmpty(renderFormToggles) && (
              <div className="ferry-routes__form-type-toggles" role="tablist">
                {renderFormToggles}
              </div>
            )}
            {crossingsLoading ? (
              <BrandLoader />
            ) : (
              <>{routeFormElement && routeFormElement}</>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FerryRoutesFormsContainer.propTypes = {
  availabilityErrors: arrayOf(node),
  customerAccount: oneOfType([shape(), bool]),
  travelAgentAccount: oneOfType([shape(), bool]),
  crossingsLoading: bool,
  labels: shape().isRequired,
  openModal: func.isRequired,
  renderFormToggles: array,
  routeFormElement: element,
  routeMap: string.isRequired
}

export default FerryRoutesFormsContainer
